import { useState, useEffect, useMemo, useCallback } from "react";
import { getAllEmployeeCommunications } from "../../services/EmployeeService";
import { alerts } from "../../utils/Notifications";

/**
 * Generates a custom hook to manage and filter a list of employee.
 *
 * @param {object} id - The unique identifier of the employee.
 * @param {string} type - The type of communication.
 * @param {string} name - The name of the employee.
 * @param {boolean} show - A boolean flag to determine if the employee should be displayed.
 * @return {object} An object containing functions and states for sorting, filtering, and handling employee data.
 */
const useListComunications = ({ id, type, name, show }) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "ascending",
  });
  const [filterValue, setFilterValue] = useState("");

  const sortedEmployees = useMemo(() => {
    if (sortConfig.key === null) {
      return [];
    }

    const sortedEmployees = [...employees].sort((a, b) => {
      const keyA = a.attributes[sortConfig.key];
      const keyB = b.attributes[sortConfig.key];

      return sortConfig.direction === "ascending"
        ? keyA > keyB
          ? 1
          : -1
        : keyB > keyA
        ? 1
        : -1;
    });

    return sortedEmployees;
  }, [employees, sortConfig]);

  const filteredEmployees = sortedEmployees.filter((employee) => {
    return Object.values(employee.attributes).some((value) =>
      String(value).toLowerCase().includes(filterValue.toLowerCase())
    );
  });
  const formatDate = (fecha) => {
    if (!fecha) return "";
  
    const [year, month, day] = fecha.split('-');
    const date = new Date(year, month - 1, day);
  
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  
    return formattedDate;
  };

  const csvData = filteredEmployees.map((employee) => ({
    ID: employee.id,
    Nombre: employee.attributes.nombre,
    Campaña: employee.attributes.campaing,
    Área: employee.attributes.area,

    "Tipo de contrato": employee.attributes.tipo_contrato,
    "Correo electrónico": employee.attributes.correo_electronico,
    "Correo Corporativo": employee.attributes.correo_corporativo,
    Teléfono: employee.attributes.telefono,
    Ciudad: employee.attributes.ciudad,
    Género: employee.attributes.genero,
    "Fecha de nacimiento": formatDate(employee.attributes.fecha_nacimiento),
    Profesión: employee.attributes.profesion,
    "Tiene hijo": employee.attributes.tiene_hijo === 1 ? "Si" : "No",
    "Cantidad de hijos": employee.attributes.cantidad_hijos,
  }));

  const headersCsv = Object.keys(csvData[0] || {});
  /**
   * Updates the sort configuration based on the given key.
   *
   * @param {string} key - The key to sort by
   * @return {void}
   */
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key, direction });
  };

  /**
   * A function that handles the change event for the filter.
   *
   * @param {Event} event - the event object triggering the change
   * @return {void}
   */
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };
  /**
   * A function that handles copying the values of a specified column for each employee
   *
   * @param {string} columnKey - the key of the column to copy
   * @return {void}
   */
  const handleCopyColumn = (columnKey) => {
    const columnValues = filteredEmployees.map(
      (employee) => employee.attributes[columnKey]
    );
    const textToCopy = columnValues.join("\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alerts("success", "Datos copiadas", 2000);
      })
      .catch(() => {
        alerts("error", "Error al copiar los datos!", 3000);
      });
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAllEmployeeCommunications();

      if (response.success) {
        let filteredData;

        if (type === "general") {
          filteredData = response.data;
        } else {
          filteredData = response.data.filter((employee) => {
            const { campaing_id, area_id } = employee.attributes;
            return (
              (type === "campaing" && campaing_id === id) ||
              (type === "area" && area_id === id)
            );
          });
        }

        setEmployees(filteredData);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error("Error fetching employee demographic data:", error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  }, [id, type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    requestSort,
    handleCopyColumn,
    isLoading,
    sortedEmployees,
    handleFilterChange,
    filterValue,
    filteredEmployees,
    csvData,
    formatDate,
    headersCsv,
  };
};

export { useListComunications };
