import React from "react";
import { CSVLink } from "react-csv";
import { IoMdCloudDownload } from "react-icons/io";
import { BsToggle2Off, BsToggle2On } from "react-icons/bs";
import { TbReload } from "react-icons/tb";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import User from "../User";
import { useListOperations } from "./useListOperations";

/**
 * Renders the ListOperations component.
 *
 * @param {Object} id - The id of the area, campaing or general
 * @param {string} type - The type of the employee
 * @param {string} name - The name of the communication
 * @return {JSX.Element} The rendered ListOperations component
 */

const ListOperations = ({ id, type, name }) => {
  const {
    requestSort,
    handleCopyColumn,
    isLoading,
    sortedEmployees,
    handleFilterChange,
    filterValue,
    filteredEmployees,
    csvData,
    formatDate,
    headersCsv,
    calculateAge,
    handleUpdate,
  } = useListOperations({ id, type, name });

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <Container fluid className="mx-5 my-2">
            <User color="blue" marginX={4} position={true} marginY={2} />

            <h2 className="text-center text-blue">Personal - {name}</h2>

            <section>
              <Row className="justify-content-between mb-1">
                <Col md={4} className="d-flex flex-column">
                  <span className="fw-bold text-blue">
                    Módulo: Comunicaciones
                  </span>
                  <span className="text-blue mb-0">
                    Registros: {filteredEmployees.length}
                  </span>
                </Col>

                <Col md={8} className="d-flex justify-content-end">
                  <Form>
                    <Form.Group controlId="filter">
                      <Form.Control
                        style={{ width: "400px" }}
                        type="text"
                        placeholder="Filtrar..."
                        value={filterValue}
                        onChange={handleFilterChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  </Form>
                  <CSVLink
                    data={csvData}
                    headers={headersCsv}
                    filename={"datos_empleados.csv"}
                  >
                    <IoMdCloudDownload
                      color="#0096C8"
                      size={30}
                      className="mx-1"
                    />
                  </CSVLink>
                  <TbReload
                    title="Actualizar la información"
                    role="button"
                    color="#0096C8"
                    size={25}
                    className="mx-1"
                    onClick={handleUpdate}
                  />
                </Col>
              </Row>
            </section>

            <div className="table-container">
              <Table
                striped
                bordered
                hover
                size="md"
                className="table-employee"
              >
                <thead>
                  <tr className="text-center">
                    <th onClick={() => requestSort("estado")}>Estado</th>
                    <th onClick={() => requestSort("id")}>ID</th>
                    <th onClick={() => requestSort("nombre")}>Nombre</th>
                    <th onClick={() => requestSort("campaing")}>Campaña</th>
                    <th onClick={() => requestSort("cargo")}>Cargo</th>
                    <th onClick={() => requestSort("tipo_identificacion")}>
                      Tipo de Identificación
                    </th>
                    <th onClick={() => requestSort("numero_identificacion")}>
                      Número de Identificación
                    </th>
                    <th onClick={() => requestSort("modalidad_trabajo")}>
                      Modalidad de Trabajo
                    </th>
                    <th onClick={() => requestSort("tipo_contrato")}>
                      Tipo de contrato
                    </th>
                    <th onClick={() => requestSort("fecha_inicio")}>
                      Fecha de Inicio
                    </th>
                    <th onClick={() => requestSort("fecha_fin")}>
                      Fecha de Fin
                    </th>
                    <th onClick={() => requestSort("correo_electronico")}>
                      Correo electrónico{" "}
                    </th>

                    <th onClick={() => requestSort("correo_corporativo")}>
                      Correo Corporativo
                    </th>
                    <th onClick={() => requestSort("telefono")}>Teléfono</th>
                    <th onClick={() => requestSort("ciudad")}>Ciudad</th>

                    <th onClick={() => requestSort("fecha_nacimiento")}>
                      Fecha de nacimiento
                    </th>
                    <th onClick={() => requestSort("edad")}>Edad</th>

                    <th onClick={() => requestSort("lugar_nacimiento")}>
                      Lugar de nacimiento
                    </th>
                    <th onClick={() => requestSort("direccion")}>Dirección</th>
                    <th onClick={() => requestSort("nombre_contacto")}>
                      Nombre del contacto
                    </th>
                    <th onClick={() => requestSort("telefono_contacto")}>
                      Teléfono del contacto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees.map((employee) => (
                    <tr key={employee.id}>
                      <td>
                        {employee.attributes.estado === 1 ? (
                          <BsToggle2On color="#0096C8" />
                        ) : (
                          <BsToggle2Off color="#E74242" />
                        )}
                      </td>
                      <td>{employee.id}</td>
                      <td>{employee.attributes.nombre}</td>
                      <td>{employee.attributes.campaing}</td>
                      <td>{employee.attributes.cargo}</td>

                      <td>{employee.attributes.tipo_identificacion}</td>
                      <td>{employee.attributes.numero_identificacion}</td>
                      <td>{employee.attributes.modalidad_trabajo}</td>
                      <td>{employee.attributes.tipo_contrato}</td>
                      <td>{employee.attributes.fecha_inicio}</td>
                      <td>{employee.attributes.fecha_fin}</td>
                      <td>{employee.attributes.correo_electronico}</td>
                      <td>{employee.attributes.correo_corporativo}</td>
                      <td>{employee.attributes.telefono}</td>
                      <td>{employee.attributes.ciudad}</td>

                      <td>
                        {formatDate(employee.attributes.fecha_nacimiento)}
                      </td>
                      <td>
                        {calculateAge(employee.attributes.fecha_nacimiento)}
                      </td>
                      <td>{employee.attributes.lugar_nacimiento}</td>
                      <td>{employee.attributes.direccion}</td>
                      <td>{employee.attributes.nombre_contacto}</td>
                      <td>{employee.attributes.telefono_contacto}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default ListOperations;
