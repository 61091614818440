import React from "react";
import { IoPersonAddSharp } from "react-icons/io5";
import { Button, Form, Modal } from "react-bootstrap";
import { useModalConfigAdd } from "./useModalConfigAdd";
import Loading from "../Loading";
/**
 * Creates a modal configuration for adding new items.
 *
 * @param {boolean} show - Indicates whether the modal should be shown
 * @param {function} onHide - Callback function for when the modal is hidden
 * @param {string} title - The title of the modal
 * @param {string} type - The type of the modal
 * @return {JSX.Element} The modal configuration for adding new items
 */
const ModalConfigAdd = ({ show, onHide, title, type }) => {
  const {
    showUserFields,
    handleEnviarClick,
    handleModalHide,
    nameRef,
    emailRef,
    roleRef,
    passwordRef,
    errors,
    isLoading
  } = useModalConfigAdd({
    title,
    type,
    onHide,
  });

 
  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        handleModalHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-blue d-flex align-items-center ">
          <IoPersonAddSharp size={25} className="me-2" /> {title}
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" ref={nameRef} required />
              {errors.name && (
                <small className="text-danger">{errors.name}</small>
              )}
            </Form.Group>
            {showUserFields && (
              <>
                <Form.Group>
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingresa tu correo electrónico"
                    ref={emailRef}
                    required
                  />
                  {errors.email && (
                    <small className="text-danger">{errors.email}</small>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Rol</Form.Label>
                  <Form.Select ref={roleRef} required defaultValue={"DEFAULT"}>
                    <option value="DEFAULT" disabled>
                      Selecciona un rol
                    </option>
                    <option value="1">Administrador</option>
                    <option value="2">Recursos Humanos</option>
                    <option value="3">Comunicaciones</option>
                    <option value="4">Seguridad y Salud en el Trabajo</option>
                    <option value="5">Tecnologías de la Información</option>
                    <option value="6">Visualizador</option>
                    <option value="7">Soporte</option>
                    <option value="8">Operaciones</option>
                  </Form.Select>
                  {errors.role && (
                    <small className="text-danger">{errors.role}</small>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    ref={passwordRef}
                    required
                  />
                  {errors.password && (
                    <small className="text-danger">{errors.password}</small>
                  )}
                </Form.Group>
              </>
            )}
          </Form>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button className="bg-blue" onClick={handleEnviarClick}>
          Crear
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ModalConfigAdd };

