import { useLocation } from "react-router-dom";
import { useMemo } from "react";

/**
 * Hook to handle user permissions and retrieve update password flag.
 *
 * @return {Object} Object containing user permissions and the update password flag.
 */
const useHome = () => {
  // Fetch the user's role from localStorage
  const role = localStorage.getItem("role");

  // Retrieve the current location object
  const location = useLocation();

  const hasRole = (validRoles) => validRoles.includes(role);

  const permissions = useMemo(
    () => ({
      isAdmin: role === "Administrador",
      canViewRH: hasRole(["Administrador", "Recursos Humanos", "Visualizador"]),
      canViewSgsst: hasRole([
        "Administrador",
        "Seguridad y Salud en el Trabajo",
        "Recursos Humanos",
      ]),
      canViewComunicaciones: hasRole(["Administrador", "Comunicaciones"]),
      canViewSupport: hasRole(["Administrador", "Soporte"]),
      canViewOperations: hasRole(["Administrador", "Operaciones"]),
    }),
    [role]
  );

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const updatePassword = searchParams.get("updatePassword");

  // Return the permissions and the updatePassword flag
  return {
    ...permissions,
    updatePassword,
  };
};

export { useHome };
