import React from "react";
import { Card, Button, Container, Image, Col, Row } from "react-bootstrap";
import { FaPeopleGroup, FaComputer } from "react-icons/fa6";
import { RiMentalHealthFill } from "react-icons/ri";
import { IoIosMegaphone } from "react-icons/io";
import { MdWifiCalling3 } from "react-icons/md";
import UpdatePassword from "../../Components/UpdatePassword";
import Header from "../../Components/Header";
import { useHome } from "./useHome";
import imageHome from "../../Assets/mascota-home.png";
import "./home.css";

/**
 * CardItem Component
 *
 * This component renders a card that displays an icon, a title, some descriptive text,
 * and a button. Each card will have a uniform height and will be flexibly aligned to
 * ensure that the icon is centered vertically and the button is aligned at the bottom.
 *
 * Props:
 * - icon: JSX.Element - The icon element to display at the top of the card.
 * - title: string - The title of the card.
 * - text: string - A brief description or text for the card.
 * - href: string - The URL to navigate to when the button is clicked.
 *
 * @param {JSX.Element} icon - The icon displayed at the top of the card.
 * @param {string} title - The title of the card.
 * @param {string} text - The descriptive text shown in the card.
 * @param {string} href - The URL for the button.
 *
 * @returns {JSX.Element} A fully structured card component.
 */
const CardItem = ({ icon, title, text, href }) => (
  <Col lg={4} md={6} sm={12} className="mb-3 d-flex">
    <Card className="shadow-lg rounded w-100 d-flex flex-column h-100">
      <Card.Body className="d-flex flex-column flex-grow-1 justify-content-center align-items-center text-center">
        {icon} {/* Icon to visually represent the section */}
        <Card.Title className="fw-bold mt-2">{title}</Card.Title>{" "}
        {/* Card Title */}
        <Card.Text className="flex-grow-1">{text}</Card.Text>{" "}
        {/* Card descriptive text */}
      </Card.Body>
      <div className="d-flex justify-content-center mb-3">
        {" "}
        {/* Centers the button */}
        <Button className="bg-blue w-50" href={href}>
          Ingresar
        </Button>
      </div>
    </Card>
  </Col>
);

/**
 * Home Component
 *
 * This is the main component that renders the home dashboard with various cards.
 * Each card represents a different functional area based on user permissions. The layout
 * ensures 3 cards per row at large screen sizes, 2 cards per row at medium sizes, and 1
 * card per row at smaller screens.
 *
 * Cards displayed:
 * - Gestión del contratista
 * - Gestión de empleados
 * - SG - SST
 * - Comunicaciones
 * - Soporte
 * - Operaciones
 *
 * Cards are conditionally rendered based on user permissions fetched from `useHome` hook.
 *
 * @returns {JSX.Element} A home dashboard with permission-based cards.
 */
const Home = () => {
  // Destructuring user permissions from the useHome hook.
  const {
    canViewRH,
    canViewSgsst,
    canViewComunicaciones,
    canViewSupport,
    updatePassword,
    canViewOperations
  } = useHome();

  // Define the data for each card, including icon, title, text, link, and visibility.
  const cardsData = [
    {
      icon: <FaPeopleGroup size="60" color="#0096C8" />,
      title: "Gestión del contratista",
      text: "Podrás gestionar, organizar y observar información detallada de cada contratista de la empresa.",
      href: "/personal/contratistas",
      isVisible: canViewRH,
    },
    {
      icon: <FaPeopleGroup size="60" color="#0096C8" />,
      title: "Gestión de empleados",
      text: "Podrás gestionar, organizar y observar información detallada de cada empleado de la empresa.",
      href: "/personal/empleados",
      isVisible: canViewRH,
    },
    {
      icon: <RiMentalHealthFill size="60" color="#0096C8" />,
      title: "SG - SST",
      text: "Información demográfica de los colaboradores activos.",
      href: "/sgsst",
      isVisible: canViewSgsst,
    },
    {
      icon: <IoIosMegaphone size="60" color="#0096C8" />,
      title: "Comunicaciones",
      text: "Información de los colaboradores activos para enviar comunicados personalizados y grupales.",
      href: "/comunicaciones",
      isVisible: canViewComunicaciones,
    },
    {
      icon: <FaComputer size="60" color="#0096C8" />,
      title: "Soporte",
      text: "Gestión y registro de equipos de cómputo asignados y sus respectivas actas.",
      href: "/soporte",
      isVisible: canViewSupport,
    },
    {
      icon: <MdWifiCalling3 size="60" color="#0096C8" />,
      title: "Operaciones",
      text: "Información de los colaboradores asociados a campañas.",
      href: "/operaciones",
      isVisible: canViewOperations,
    },
  ];

  return (
    <>
      {/* Displays a component for updating the password */}
      <UpdatePassword updatePassword={updatePassword} />

      {/* Header Component */}
      <Header />

      <Container className="my-3">
        <Row className="align-items-center justify-content-center mb-4">
          <Col xs="auto">
            <h1 className="text-blue display-4 fw-bold">Talento Humano</h1>
          </Col>
          <Col xs="auto">
            <Image
              src={imageHome}
              alt="Mascota ccg"
              style={{ width: "100px" }}
              fluid
            />
          </Col>
        </Row>

        {/* Render the cards in a grid layout based on user permissions */}
        <Row className="justify-content-center align-items-stretch">
          {cardsData.map(
            (card, index) =>
              card.isVisible && (
                <CardItem
                  key={index}
                  icon={card.icon}
                  title={card.title}
                  text={card.text}
                  href={card.href}
                />
              )
          )}
        </Row>
      </Container>
    </>
  );
};

export default Home;
